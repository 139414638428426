// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-allergeni-js": () => import("./../src/pages/allergeni.js" /* webpackChunkName: "component---src-pages-allergeni-js" */),
  "component---src-pages-connected-js": () => import("./../src/pages/connected.js" /* webpackChunkName: "component---src-pages-connected-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-wifi-js": () => import("./../src/pages/wifi.js" /* webpackChunkName: "component---src-pages-wifi-js" */)
}

